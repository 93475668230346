// Import all plugins
import * as bootstrap from 'bootstrap';
import "./import-jquery"
import '@fortawesome/fontawesome-free/js/all.js';

function autoPlayYouTubeModal() {
    var triggerOpen = $("body").find('[data-tagVideo]');
    triggerOpen.on('click', function() {
        var theModal = $(this).data("bs-target"),
            videoSRC = $(this).attr("data-tagVideo"),
            videoSRCauto = videoSRC + "?autoplay=1";
        $(theModal + ' iframe').attr('src', videoSRCauto);
        $(theModal + ' button.btn-close').on('click', function() {
            $(theModal + ' iframe').attr('src', videoSRC);
        });
    });
}

jQuery(function() {
    autoPlayYouTubeModal();
});
